import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';

const SortableMeasure = (props: any) => {
  const { measure } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: measure.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
      <div style={{ border: '1px solid #dce4ec', borderRadius: '0.25rem', marginBottom: '3px' }}>
        <div className="p-1 d-flex align-items-center">
          <i className="mdi mdi-drag mr-2" data-testid={`measureDragIcon_${measure.id}`}></i>
          <span data-testid={`measureDragName_${measure.id}`}>{measure.name}</span>
        </div>
      </div>
    </div>
  ) 
}

export default SortableMeasure;
