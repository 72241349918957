import React from 'react';
import { ApiService } from '../../services/ApiService';
import { csrfToken } from '../../common/phxSecurity';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Spinner from '../shared/Spinner';
import { DebouncedFunc, debounce } from 'lodash';
import CashFlowReportSelector from './CashFlowReportSelector';
import CashFlowReportTable from './CashFlowReportTable';

interface Props {
  valuations: { [key: string]: any }[];
  dimensions: { [key: string]: any };
  measures: { [key: string]: any };
}

interface State {
  records: { [key: string]: any }[];
  report_configs: { id: number, name: string, dimensions: string[], measures: string[], subtotals: string[], dimension_name_overrides: { [key: string]: any }, paginate: boolean }[];
  columns: string[];
  selected_valuation: { [key: string]: any } | null;
  selected_report_config: { [key: string]: any } | null;
  loaded: boolean;
  emailing: boolean;
  report_emailed: boolean;
  paginate: boolean;
  total_pages: number | null;
  current_page: number | null;
  next_page: number | null;
  paginated_data_loaded: boolean;
  error: any;
}

class CashFlowReport extends React.PureComponent<Props, State> {
  private debouncedResizeTable: DebouncedFunc<() => void>
  private observer: IntersectionObserver | null;
  private lastItemRef: React.RefObject<HTMLTableRowElement>
  
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      records: [],
      report_configs: [],
      columns: [],
      selected_valuation: this.props.valuations[0],
      selected_report_config: null,
      loaded: false,
      emailing: false,
      report_emailed: false,
      paginate: false,
      current_page: null,
      next_page: null,
      total_pages: null,
      paginated_data_loaded: false,
      error: null };

    this.debouncedResizeTable = debounce(this.resizeTable, 200);
    this.observer = null;
    this.lastItemRef = React.createRef<HTMLTableRowElement>();
  }

  componentDidMount = () => {
    this._isMounted = true;
    window.addEventListener('resize', this.debouncedResizeTable)
    this.resizeTable();
    this.getReportConfigs();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.debouncedResizeTable)
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    return (
      <div>
        <CashFlowReportSelector
          emailing={this.state.emailing}
          emailReport={this.emailReport}
          loaded={this.state.loaded}
          records={this.state.records}
          reportEmailed={this.state.report_emailed}
          reportConfigs={this.state.report_configs}
          selectedReportConfig={this.state.selected_report_config}
          selectedValuation={this.state.selected_valuation}
          selectReport={this.selectReport}
          selectValuation={this.selectValuation}
          valuations={this.props.valuations}
        />

        { this.state.loaded ? (
          <CashFlowReportTable
            columns={this.state.columns}
            dimensions={this.props.dimensions}
            error={this.state.error}
            lastItemRef={this.lastItemRef}
            measures={this.props.measures}
            paginate={this.state.paginate}
            paginatedDataLoaded={this.state.paginated_data_loaded}
            records={this.state.records}
            selectedReportConfig={this.state.selected_report_config}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-50 mb-5">
            <Spinner width="6rem" height="6rem" />
          </div>
        ) }

      </div>
    )
  }

  private getReportConfigs = () => {
    if (!this.state?.selected_valuation) {
      this.setState({
        loaded: true
      });
      return;
    };

    ApiService.get(`/api/valuations/${this.state?.selected_valuation?.id}/report_configs/cash_flows`, {}, true)
      .then((response: AxiosResponse) => {
        if (this._isMounted) {
          const configs = response.data;

          this.setState({
            report_configs: configs,
            paginate: !!configs[0]?.paginate,
            selected_report_config: configs[0],
          }, () => {
            this.generateReportData();
          })
        }
      })
      .catch((error: AxiosError) => {
        if (this._isMounted) {
          this.setState({ error: error });
        }
      })
  }

  private generateReportData = () => {
    if (!this.state?.selected_report_config) {
      this.setState({
        loaded: true
      });
      return;
    };

    this.setState({
      records: []
    }, () => {
      if (this.state.paginate) {
        this.generatePaginatedReportData();
      } else {
        this.generateAllReportData();
      }
    })
  }

  private generatePaginatedReportData = () => {
    if (this.state.paginated_data_loaded) return;
    
    const page = this.state.next_page ? this.state.next_page : 1;

    ApiService.get(`/api/cash_flow_report`, { report_config_id: this.state?.selected_report_config?.id, valuation_uuid: this.state?.selected_valuation?.uuid, page: page }, true)
      .then((response: AxiosResponse) => {
        const paginatedDataLoaded = response.data.current_page === response.data.total_pages;
        const nextPage = paginatedDataLoaded ? null : response.data.current_page + 1;
        
        if (this._isMounted) {
          this.setState(prevState => ({
            records: [...prevState.records, ...response.data.records],
            columns: this.state.selected_report_config ? this.state.selected_report_config['dimensions'].concat(this.state.selected_report_config['measures']) : [],
            current_page: response.data.current_page,
            next_page: nextPage,
            total_pages: response.data.total_pages,
            paginated_data_loaded: paginatedDataLoaded,
            loaded: true
          }), this.observeLastItem);
        }
      })
      .catch((error: AxiosError) => {
        if (this._isMounted) {
          this.setState({ loaded: true, error: error });
        }
      })
  }

  private generateAllReportData = () => {
    ApiService.get(`/api/cash_flow_report/records`, { report_config_id: this.state?.selected_report_config?.id, valuation_uuid: this.state?.selected_valuation?.uuid }, true)
      .then((response: AxiosResponse) => {
        if (this._isMounted) {
          this.setState({
            records: response.data,
            columns: this.state.selected_report_config ? this.state.selected_report_config['dimensions'].concat(this.state.selected_report_config['measures']) : [],
            loaded: true
          })
        }
      })
      .catch((error: AxiosError) => {
        if (this._isMounted) {
          this.setState({ loaded: true, error: error });
        }
      })
  }

  private generateReportEmail = () => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken();
    axios.post(`/api/cash_flow_report`, { report_config_id: this.state?.selected_report_config?.id, valuation_uuid: this.state?.selected_valuation?.uuid }, { headers: { 'Accept': 'application/json' }, responseType: 'blob' })
      .then((response: AxiosResponse) => {
        if (this._isMounted) {
          this.setState({ emailing: false, report_emailed: true });
        }
      })
      .catch((error: AxiosError) => {
        if (this._isMounted) {
          this.setState({ emailing: false, error: error });
        }
      });
  }

  private observeLastItem = () => {
    if (this.observer) {
      this.observer.disconnect();
    }

    const { records } = this.state;
    const lastElement = this.lastItemRef.current;
    
    if (!lastElement || records.length < 5) return;

    this.observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        this.generatePaginatedReportData();
      }
    }, {
      root: document.querySelector('#cash-flow-report-table-container'),
      rootMargin: '0px',
      threshold: 0
    });

    if (lastElement) this.observer.observe(lastElement);
  }

  private emailReport = () => {
    this.setState({ emailing: true }, this.generateReportEmail)
  }

  private selectValuation = (val: { [key: string]: any }) => {
    this.setState({
      records: [],
      report_configs: [],
      selected_valuation: val,
      report_emailed: false,
      current_page: null,
      next_page: null,
      total_pages: null,
      paginated_data_loaded: false,
      loaded: false
    }, this.getReportConfigs)
  }

  private selectReport = (config: any) => {
    this.setState({
      records: [],
      selected_report_config: config,
      paginate: !!config?.paginate,
      current_page: null,
      next_page: null,
      total_pages: null,
      paginated_data_loaded: false,
      loaded: false
    }, this.generateReportData);
  }

  private resizeTable = () => {
    document.documentElement.style.setProperty('--flagship-report-table-height', `${window.innerHeight - 250}px`)
  }

}

export default CashFlowReport;
