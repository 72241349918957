import React from 'react';
import { ApiService } from '../../services/ApiService';
import { csrfToken } from '../../common/phxSecurity';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Spinner from '../shared/Spinner';
import { DebouncedFunc, debounce } from 'lodash';
import ShockReportSelector from './ShockReportSelector';
import ShockReportTable from './ShockReportTable';

interface Props {
  valuations: { [key: string]: any }[];
  report_configs: { id: number, name: string, type: string, dimensions: string[], measures: string[], subtotals: string[], dimension_name_overrides: { [key: string]: any }, paginate: boolean }[];
  dimensions: { [key: string]: any };
  measures: { [key: string]: any };
}

interface State {
  records: { [key: string]: any }[];
  columns: string[];
  selected_valuation: { [key: string]: any } | null;
  selected_report_config: { id: number, name: string, type: string, dimensions: string[], measures: string[], subtotals: string[], dimension_name_overrides: { [key: string]: any }, paginate: boolean } | null;
  loaded: boolean;
  emailing: boolean;
  report_emailed: boolean;
  paginate: boolean;
  total_pages: number | null;
  current_page: number | null;
  next_page: number | null;
  paginated_data_loaded: boolean;
  error: any;
}

class ShockReport extends React.PureComponent<Props, State> {
  private debouncedResizeTable: DebouncedFunc<() => void>
  private observer: IntersectionObserver | null;
  private lastItemRef: React.RefObject<HTMLTableRowElement>

  SHOCK_CONFIG_TYPES = {
    dollar: 'ReportConfigs::ShockDollar',
    bp: 'ReportConfigs::ShockBp',
    cpr: 'ReportConfigs::ShockCpr'
  };
  
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      records: [],
      columns: [],
      selected_valuation: this.props.valuations[0],
      selected_report_config: this.props.report_configs[0],
      loaded: false,
      emailing: false,
      report_emailed: false,
      paginate: !!this.props.report_configs[0]?.paginate,
      current_page: null,
      next_page: null,
      total_pages: null,
      paginated_data_loaded: false,
      error: null };

    this.debouncedResizeTable = debounce(this.resizeTable, 200);
    this.observer = null;
    this.lastItemRef = React.createRef<HTMLTableRowElement>();
  }

  componentDidMount = () => {
    this._isMounted = true;
    window.addEventListener('resize', this.debouncedResizeTable)
    this.resizeTable();
    this.generateReportData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.debouncedResizeTable)
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    return (
      <div>
        <ShockReportSelector
          emailing={this.state.emailing}
          emailReport={this.emailReport}
          loaded={this.state.loaded}
          records={this.state.records}
          reportEmailed={this.state.report_emailed}
          reportConfigs={this.props.report_configs}
          selectedReportConfig={this.state.selected_report_config}
          selectedValuation={this.state.selected_valuation}
          selectReport={this.selectReport}
          selectValuation={this.selectValuation}
          valuations={this.props.valuations}
        />

        { this.state.loaded ? (
          <ShockReportTable
            columns={this.state.columns}
            dimensions={this.props.dimensions}
            error={this.state.error}
            lastItemRef={this.lastItemRef}
            measures={this.props.measures}
            paginate={this.state.paginate}
            paginatedDataLoaded={this.state.paginated_data_loaded}
            records={this.state.records}
            selectedReportConfig={this.state.selected_report_config}
            shockConfigTypes={this.SHOCK_CONFIG_TYPES}
          />
        ) : this.renderLoading() }

      </div>
    )
  }

  private renderLoading = () => {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100 mt-50 mb-5">
        <Spinner width="6rem" height="6rem" />
      </div>
    )
  }

  private generateReportData = () => {
    this.setState({
      records: []
    }, () => {
      if (this.state.paginate) {
        this.generatePaginatedReportData();
      } else {
        this.generateTotaledReportData();
      }
    })
  }

  private generatePaginatedReportData = () => {
    if (this.state.paginated_data_loaded) return;
    
    const page = this.state.next_page ? this.state.next_page : 1;

    ApiService.get(`/api/shock_report`, { report_config_id: this.state?.selected_report_config?.id, valuation_uuid: this.state?.selected_valuation?.uuid, page: page }, true)
      .then((response: AxiosResponse) => {
        const paginatedDataLoaded = response.data.current_page === response.data.total_pages;
        const nextPage = paginatedDataLoaded ? null : response.data.current_page + 1;
        
        if (this._isMounted) {
          this.setState(prevState => ({
            records: [...prevState.records, ...response.data.records],
            columns: this.state.selected_report_config ? this.state.selected_report_config['dimensions'].concat(this.state.selected_report_config['measures']) : [],
            current_page: response.data.current_page,
            next_page: nextPage,
            total_pages: response.data.total_pages,
            paginated_data_loaded: paginatedDataLoaded,
            loaded: true
          }), this.observeLastItem);
        }
      })
      .catch((error: AxiosError) => {
        if (this._isMounted) {
          this.setState({ loaded: true, error: error });
        }
      })
  }

  private generateTotaledReportData = () => {
    ApiService.get(`/api/shock_report/totaled_records`, { report_config_id: this.state?.selected_report_config?.id, valuation_uuid: this.state?.selected_valuation?.uuid }, true)
      .then((response: AxiosResponse) => {
        if (this._isMounted) {
          this.setState({
            records: response.data,
            columns: this.state.selected_report_config ? this.state.selected_report_config['dimensions'].concat(this.state.selected_report_config['measures']) : [],
            loaded: true
          })
        }
      })
      .catch((error: AxiosError) => {
        if (this._isMounted) {
          this.setState({ loaded: true, error: error });
        }
      })
  }

  private generateReportEmail = () => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken();
    axios.post(`/api/shock_report`, { report_config_id: this.state?.selected_report_config?.id, valuation_uuid: this.state?.selected_valuation?.uuid }, { headers: { 'Accept': 'application/json' }, responseType: 'blob' })
      .then((response: AxiosResponse) => {
        if (this._isMounted) {
          this.setState({ emailing: false, report_emailed: true });
        }
      })
      .catch((error: AxiosError) => {
        if (this._isMounted) {
          this.setState({ emailing: false, error: error });
        }
      });
  }

  private observeLastItem = () => {
    if (this.observer) {
      this.observer.disconnect();
    }

    const { records } = this.state;
    const lastElement = this.lastItemRef.current;
    
    if (!lastElement || records.length < 5) return;

    this.observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        this.generatePaginatedReportData();
      }
    }, {
      root: document.querySelector('#shock-report-table-container'),
      rootMargin: '0px',
      threshold: 0
    });

    if (lastElement) this.observer.observe(lastElement);
  }

  private emailReport = () => {
    this.setState({ emailing: true }, this.generateReportEmail)
  }

  private selectValuation = (val: { [key: string]: any }) => {
    this.setState({
      loaded: false,
      selected_valuation: val,
      report_emailed: false,
      current_page: null,
      next_page: null,
      total_pages: null,
      paginated_data_loaded: false,
    }, this.generateReportData)
  }

  private selectReport = (config: any) => {
    this.setState({
      selected_report_config: config,
      paginate: !!config?.paginate,
      current_page: null,
      next_page: null,
      total_pages: null,
      paginated_data_loaded: false,
      loaded: false
    }, this.generateReportData);
  }

  private resizeTable = () => {
    document.documentElement.style.setProperty('--flagship-report-table-height', `${window.innerHeight - 250}px`)
  }

}

export default ShockReport;
