import React from 'react';
import * as phxDate from '../../common/phxDate';

interface Props {
  emailing: boolean;
  emailReport: () => void;
  loaded: boolean;
  records: { [key: string]: any }[];
  reportEmailed: boolean;
  reportConfigs: { id: number, name: string, dimensions: string[], measures: string[], subtotals: string[], dimension_name_overrides: { [key: string]: any }, paginate: boolean }[];
  selectedReportConfig: { [key: string]: any } | null;
  selectedValuation: { [key: string]: any } | null;
  selectReport: (arg: any) => void;
  selectValuation:(arg: any) => void;
  valuations: { [key: string]: any }[];
}

const ShockReportSelector = (props: Props) => {
  const {
    emailing,
    emailReport,
    loaded,
    records,
    reportEmailed,
    reportConfigs,
    selectedReportConfig,
    selectedValuation,
    selectReport,
    selectValuation,
    valuations
  } = props;

  const renderReportEmailButtonText = () => {
    if (reportEmailed) {
      return <span>Generating all shock reports...</span>
    } else {
      return <span>Generate all shock reports to email</span>
    }
  }

  const formatPhxDate = (valuation: { [key: string]: any }) => {
    return phxDate.formatShort(phxDate.stringToDate(valuation.as_of));
  }

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="d-flex flex-row align-items-center mb-3">
        <div className="mr-2">Valuation as of Date:</div>
        <div className="dropdown">
          <button className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" disabled={ !loaded }>
            { selectedValuation ? formatPhxDate(selectedValuation) : 'Select Date' }
          </button>
          <div className="dropdown-menu">
            { valuations.map((val, index) => {
              return <button key={index} className="dropdown-item" type="button" disabled={selectedValuation?.id === val.id} onClick={() => { selectValuation(val) }}>{ formatPhxDate(val) }</button>
            }) }
          </div>
        </div>
      </div>

      <div className="d-flex flex-row align-items-center ml-3 mb-3">
        <div className="mr-2">Shock Report:</div>
        <div className="dropdown">
          <button className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" disabled={ !loaded }>
            { selectedReportConfig ? selectedReportConfig.name : 'Select Report' }
          </button>
          <div className="dropdown-menu">
            { reportConfigs.map((rc, index) => {
              return <button key={index} className="dropdown-item" type="button" disabled={selectedReportConfig?.id === rc.id} onClick={() => { selectReport(rc) }}>{ rc.name }</button>
            }) }
          </div>
        </div>
      </div>

      <div className="d-flex flex-row align-items-center mb-3 ml-auto">
        <button type="button" className="btn btn-primary" disabled={!loaded || emailing || records.length === 0 || reportEmailed } onClick={() => { emailReport() }} data-toggle="tooltip" data-placement="left" title="All shock reports for the selected date will be emailed in a single .xlsx file with a tab for each shock report">
          { renderReportEmailButtonText() }
        </button>
      </div>
    </div>
  );
}

export default ShockReportSelector;
