import React from 'react';

const SubtotalSelections = (props: any) => {
  const { dimensions, subtotals, setSubtotals } = props;

  const handleSubtotalsChange = (event: React.FormEvent<HTMLSelectElement>) => {
    let checkedSubtotals: string[] = [];

    if (event.target.checked) {
      checkedSubtotals = [...subtotals, event.currentTarget.value];
    } else {
      checkedSubtotals = subtotals.filter((subtotal: string) => subtotal !== event.currentTarget.value);
    }

    setSubtotals(checkedSubtotals);
  }

  return (
    <>
      <label>Subtotals</label>
      {dimensions.length ? (
        <div className="overflow-auto" style={{ maxHeight: '10rem', border: '1px solid #dce4ec', borderRadius: '0.25rem', padding: '5px' }}>
          {dimensions?.map((subtotal: any, i: number) => {     
            return (
              <React.Fragment key={i}>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id={`subtotalCheckbox_${i}`} data-testid={`subtotalCheckbox_${i}`} value={subtotal.id} onChange={handleSubtotalsChange} checked={subtotals.includes(subtotal.id)} />
                  <label className="form-check-label" htmlFor={`subtotalCheckbox_${i}`}>{subtotal.name}</label>
                </div>
              </React.Fragment>
            ) 
          })}
        </div>
      ) : (
        <div>
          <em>No dimensions selected</em>
        </div>
      )}
    </>
  );
}

export default SubtotalSelections;
