import React, { useEffect, useState } from 'react';
import { csrfToken } from '../../common/phxSecurity';
import DimensionsSelections from './report_config_form/DimensionSelections';
import MeasureSelections from './report_config_form/MeasureSelections';
import DimensionNameOverrides from './report_config_form/DimensionOverrides';
import SubtotalSelections from './report_config_form/SubtotalSelections';

interface Props {
  report_config: { id: number, name: string, type: string, dimensions: string[], measures: string[], subtotals: string[], dimension_name_overrides: { [key: string]: any }, paginate: boolean, priority: number };
  client_id: number;
  type_selections: { id: string, name: string }[];
  edit_mode: boolean;
  pricing_dimensions: { [key: string]: any };
  pricing_measures: { [key: string]: any };
  shock_dimensions: { [key: string]: any };
  shock_measures: { [key: string]: any };
  pre_selected_shock_measures: { id: string, name: string }[];
  redirect_path: any;
}

const ReportConfigForm = (props: Props) => {
  const { report_config, client_id, type_selections, edit_mode, pricing_dimensions, pricing_measures, shock_dimensions, shock_measures, pre_selected_shock_measures, redirect_path } = props;
  const [type, setType] = useState<string>(props?.report_config?.type || "");
  const [name, setName] = useState<string>(props?.report_config?.name || "");
  const [paginate, setPaginate] = useState<boolean>(props?.report_config?.paginate || false);
  const [priority, setPriority] = useState<number>(props?.report_config?.priority || 0);
  const [dimensions, setDimensions] = useState<{ id: string, name: string }[]>([]);
  const [measures, setMeasures] = useState<{ id: string, name: string }[]>([]);
  const [subtotals, setSubtotals] = useState<string[]>( props?.report_config?.subtotals || []);
  
  const [dimensionSelections, setDimensionSelections] = useState<{ [key: string]: any }[]>([]);
  const [measureSelections, setMeasureSelections]: any[] = useState<{ [key: string]: any }[]>([]);
  
  const [dimensionNameOverrides, setDimensionNameOverrides] = useState<{ [key: string]: any }>(props?.report_config?.dimension_name_overrides || {});
  const [dimensionNameOverrideDimension, setDimensionNameOverrideDimension] = useState<string>('');
  const [dimensionNameOverrideName, setDimensionNameOverrideName] = useState<string>('');
  
  const [pricingTypes] = useState<string[]>(['ReportConfigs::Pricing', 'ReportConfigs::Pricings::Template']);
  const [shockTypes] = useState<string[]>(['ReportConfigs::ShockDollar', 'ReportConfigs::ShockBp', 'ReportConfigs::ShockCpr', 'ReportConfigs::ShockDollars::Template', 'ReportConfigs::ShockBps::Template', 'ReportConfigs::ShockCprs::Template']);
  
  const [pricingDimensionSelections] = useState<{ id: string, name: string }[]>(() => {
    return Object.keys(pricing_dimensions).map((key) => {
      return { id: key, name: pricing_dimensions[key] };
    });
  });
  const [pricingMeasureSelections] = useState<{ id: string, name: string }[]>(() => {
    return Object.keys(pricing_measures).map((key) => {
      return { id: key, name: pricing_measures[key] };
    });
  });
  const [shockDimensionSelections] = useState<{ id: string, name: string }[]>(() => {
    return Object.keys(shock_dimensions).map((key) => {
      return { id: key, name: shock_dimensions[key] };
    });
  });
  const [shockMeasureSelections] = useState<{ id: string, name: string }[]>(() => {
    return Object.keys(shock_measures).map((key) => {
      return { id: key, name: shock_measures[key] };
    });
  });

  const [error, setError] = useState<string>();

  const [dimensionsMap, setDimensionsMap] = useState<{ [key: string]: any }>({});

  const [measuresMap, setMeasuresMap] = useState<{ [key: string]: any }>({});

  useEffect(()=>{
    if (pricingTypes.includes(report_config?.type)) {
      setConfiguredPricingStates();
      setDimensionsMap(pricing_dimensions);
      setMeasuresMap(pricing_measures);
    } else if (shockTypes.includes(report_config?.type)) {
      setConfiguredShockStates();
      setDimensionsMap(shock_dimensions);
      setMeasuresMap(shock_measures);
    }
  }, [])

  const handleNameChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setName(event.currentTarget.value);
  }

  const handlePriorityChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setPriority(Number(event.currentTarget.value));
  }

  const handlePaginateChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setPaginate(event.currentTarget.value === "true");
  }

  const handleReportConfigTypeChange = (event: React.FormEvent<HTMLSelectElement>) => {
    if (pricingTypes.includes(event.currentTarget.value)) {
      setPricingSelections();
      setDimensionsMap(pricing_dimensions);
      setMeasuresMap(pricing_measures);
    } else if (shockTypes.includes(event.currentTarget.value)) {
      setShockSelections();
      setDimensionsMap(shock_dimensions);
      setMeasuresMap(shock_measures);
    }
    resetFormOnTypeChange(event.currentTarget.value);
    setType(event.currentTarget.value );
  }

  const resetMeasureDefaults: any = (configType: string) => {
    if (pricingTypes.includes(configType)) {
      return pricingMeasureSelections;
    } else if (shockTypes.includes(configType)) {
      return pre_selected_shock_measures;
    } else {
      return [];
    }
  }

  const resetFormOnTypeChange = (type: string) => {
    setName("");
    setPriority(0);
    setPaginate(false);
    setDimensionNameOverrideDimension("");
    setDimensionNameOverrideName("");
    setDimensionNameOverrides({});
    setDimensions([]);
    setMeasures(resetMeasureDefaults(type));
    setSubtotals([]);
  }

  const setPricingSelections = () => {
    setDimensionSelections(pricingDimensionSelections);
    setMeasureSelections(pricingMeasureSelections);
  }

  const setShockSelections = () => {
    setDimensionSelections(shockDimensionSelections);
    setMeasureSelections(shockMeasureSelections);
  }

  const setConfiguredPricingStates = () => {
    setPricingSelections();
    setDimensions(() => {
      return props?.report_config?.dimensions.map((dimension: string) => {
        return { id: dimension, name: pricing_dimensions[dimension] };
      })
    });
    setMeasures(() => {
      return props?.report_config?.measures.map((measure: string) => {
        return { id: measure, name: pricing_measures[measure] };
      })
    });
  }

  const setConfiguredShockStates = () => {
    setShockSelections();
    setDimensions(() => {
      return props?.report_config?.dimensions.map((dimension: string) => {
        return { id: dimension, name: shock_dimensions[dimension] };
      })
    });
    setMeasures(() => {
      return props?.report_config?.measures.map((measure: string) => {
        return { id: measure, name: shock_measures[measure] };
      })
    });
  }

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setError(undefined);

    if (edit_mode) {
      fetch(`/api/report_configs/${report_config.id}/`, { method: "PUT", redirect: 'follow', headers: buildHeaders(), body: JSON.stringify(buildParams()) })
        .then( async (response) => {
          if (response.redirected) {
            return window.location.assign(response.url);
          } else {
            const errorData = await response.json();
            setError(errorData?.errors);
          }
        });
    } else {
      fetch(`/api/report_configs/`, { method: "POST", redirect: 'follow', headers: buildHeaders(), body: JSON.stringify(buildParams()), })
        .then( async (response) => {
          if (response.redirected) {
            return window.location.assign(response.url);
          } else {
            const errorData = await response.json();
            setError(errorData?.errors);
          }
        });
    }
  }

  const buildParams = () => {
    return {
      report_config: {
        type: type,
        client_id: client_id,
        name: name,
        priority: priority,
        paginate: paginate,
        dimension_name_overrides: dimensionNameOverrides,
        dimensions: dimensions.map((d) => d.id),
        measures: measures.map((m) => m.id),
        subtotals: subtotals
      }
    }
  }

  const buildHeaders = () => {
    const headers: HeadersInit = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('X-CSRF-TOKEN', csrfToken());
    return headers;
  }

  const cancel = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    return window.location.assign(redirect_path);
  }

  const renderReportConfigTypeOptions = (): React.HTMLProps<HTMLOptionElement>[] => {
    return type_selections.map((config_type: any, i: number) => {
      return <option key={i} value={config_type.id}>{config_type.name}</option>;
    });
  }

  return (
    <div>
      { error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : null }

      <form onSubmit={submitForm}>
        <div className="row mb-3">
          <div className="col-4">
            <div className="form-group mb-3">
              <label htmlFor="type">Type</label>
              <select
                className='form-control'
                data-testid="typeSelect"
                name="type"
                value={type}
                onChange={handleReportConfigTypeChange}
                disabled={edit_mode}
                required>
                  <option value="">---</option>
                  {renderReportConfigTypeOptions()}
              </select>
            </div>

            { !!type ? (
              <>
                <div className="form-group mb-3">
                  <label htmlFor="name" className="mb-2">Name</label>
                  <input type="name" className="form-control" id="name" value={name} onChange={handleNameChange} required />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="priority" className="mb-2">Priority</label>
                  <input type="number" className="form-control" id="priority" min={0} max={999} value={priority} onChange={handlePriorityChange} />
                </div>
                
                <div className="form-group mb-3">
                  <label>Paginate</label>
                  <div className="ml-2">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="paginateTrue" id="paginateTrue" data-testid="paginateTrue" value="true" checked={paginate === true} onChange={handlePaginateChange} />
                      <label className="form-check-label" htmlFor="paginateTrue">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="paginateFalse" id="paginateFalse" data-testid="paginateFalse" value="false" checked={paginate === false} onChange={handlePaginateChange} />
                      <label className="form-check-label" htmlFor="paginateFalse">
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <DimensionNameOverrides
                  dimensionsMap={dimensionsMap}
                  dimensionSelections={dimensionSelections}
                  dimensionNameOverrides={dimensionNameOverrides}
                  dimensionNameOverrideDimension={dimensionNameOverrideDimension}
                  dimensionNameOverrideName={dimensionNameOverrideName}
                  setDimensionNameOverrides={setDimensionNameOverrides}
                  setDimensionNameOverrideDimension={setDimensionNameOverrideDimension}
                  setDimensionNameOverrideName={setDimensionNameOverrideName}
                  >
                </DimensionNameOverrides>
              </>
            ) : null }

            <div className="mt-5">
              <button type="button" className="btn btn-secondary mr-1" onClick={cancel}>Cancel</button>
              <button type="submit" className="btn btn-primary" disabled={!type}>{ edit_mode ? 'Update' : 'Create' }</button>
            </div>
          </div>

          { !!type ? (
            <div className="col-8">
              <div className="row">
                <div className="col-4">
                  <DimensionsSelections dimensionSelections={dimensionSelections} dimensions={dimensions} setDimensions={setDimensions} dimensionsMap={dimensionsMap}></DimensionsSelections>
                </div>
                <div className="col-4">
                  <MeasureSelections measureSelections={measureSelections} measures={measures} setMeasures={setMeasures} measuresMap={measuresMap}></MeasureSelections>
                </div>
                <div className="col-4">
                  <SubtotalSelections dimensions={dimensions} subtotals={subtotals} setSubtotals={setSubtotals}></SubtotalSelections>
                </div>
              </div>
            </div>
          ) :  null }
        </div>
      </form>
    </div>
  );
}

export default ReportConfigForm;
