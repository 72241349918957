import React from 'react';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import SortableDimension from './SortableDimension';

const DimensionsSelections = (props: any) => {
  const { dimensionSelections, dimensions, setDimensions, dimensionsMap } = props;

  const handleDimensionDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldDimensions = [...dimensions];
      const oldIndex = oldDimensions.findIndex((dimension) => dimension.id === active.id);
      const newIndex = oldDimensions.findIndex((dimension) => dimension.id === over.id);
      const newDimensions = arrayMove(oldDimensions, oldIndex, newIndex);
      setDimensions(newDimensions);
    }
  }

  const handleDimensionsChange = (event: React.FormEvent<HTMLSelectElement>) => {
    let checkedDimensions: any[] = [];

    if (event.target.checked) {
      checkedDimensions = [...dimensions, {id: event.currentTarget.value, name: dimensionsMap[event.currentTarget.value]}];
    } else {
      checkedDimensions = dimensions.filter((dimension: any) => dimension.id !== event.currentTarget.value);
    }

    setDimensions(checkedDimensions);
  }

  return (
    <div>
      <div>
        <label>Dimensions</label>
        <div className="overflow-auto" style={{ height: '10rem', border: '1px solid #dce4ec', borderRadius: '0.25rem', padding: '5px' }}>
          {dimensionSelections?.map((dimension: any, i: number) => {     
            return (
              <React.Fragment key={i}>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id={`dimensionCheckbox_${i}`} data-testid={`dimensionCheckbox_${i}`} value={dimension.id} onChange={handleDimensionsChange} checked={!!dimensions.find((d: any) => d.id == dimension.id)} />
                  <label className="form-check-label" htmlFor={`dimensionCheckbox_${i}`}>{dimension.name}</label>
                </div>
              </React.Fragment>
            ) 
          })}
        </div>
      </div>

      <div className="mt-3">
        <label>Dimensions Order</label>
        {dimensions.length ? (
          <DndContext onDragEnd={handleDimensionDragEnd}>
            <SortableContext items={dimensions}>
              {dimensions?.map((dimension: any, i: number) => {     
                return <SortableDimension key={i} dimension={dimension}></SortableDimension>
              })}
            </SortableContext>
          </DndContext>
        ) : (
          <div>
            <em>No dimensions selected</em>
          </div>
        )}
      </div>
    </div>
  );
}

export default DimensionsSelections;
