import React from 'react';

const DimensionNameOverrides = (props: any) => {
  const { 
    dimensionsMap,
    dimensionSelections,
    dimensionNameOverrides,
    dimensionNameOverrideDimension,
    dimensionNameOverrideName,
    setDimensionNameOverrides,
    setDimensionNameOverrideDimension,
    setDimensionNameOverrideName
  } = props;

  const handleDimensionsNameOverrideDimensionChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setDimensionNameOverrideDimension(event.currentTarget.value);
  }

  const handleDimensionsNameOverrideNameChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setDimensionNameOverrideName(event.currentTarget.value);
  }

  const addDimensionNameOverride = () => {
    const newOverride = { [dimensionNameOverrideDimension]: dimensionNameOverrideName };
    setDimensionNameOverrides({ ...dimensionNameOverrides, ...newOverride });
    setDimensionNameOverrideDimension("");
    setDimensionNameOverrideName("");
  }

  const removeDimensionNameOverride = (key: string) => {
    const clone = {...dimensionNameOverrides};
    delete clone[key];
    setDimensionNameOverrides(clone);
  }

  const renderDimensionNameOverridesTable = () => {
    return (
      <div className="mt-1">       
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th scope="col">Dimension</th>
              <th scope="col">Override Name</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            { Object.keys(dimensionNameOverrides).map((key: string) => {
              return (
                <tr key={key}>
                  <td>{dimensionsMap[key]}</td>
                  <td>{dimensionNameOverrides[key]}</td>
                  <td>
                    <button type="button" className="btn btn-sm btn-danger float-right" onClick={() => removeDimensionNameOverride(key)}>Remove</button>
                  </td>
                </tr>
              )
            }) }
          </tbody>
        </table>
      </div>
    );
  }

  const renderDimensionOverrideOptions = (): React.HTMLProps<HTMLOptionElement>[] => {
    return dimensionSelections.map((dimension: any, i: number) => {
      return <option key={i} value={dimension.id}>{dimension.name}</option>;
    });
  }

  return (
    <div className="d-flex flex-column ">
      <label>Dimension Name Overrides</label>
      <div className="mb-2">
        <div className="mb-2">
          <select
            className="form-control"
            data-testid="dimensionNameOverrideDimension"
            name="dimensionNameOverrideDimension"
            value={dimensionNameOverrideDimension}
            onChange={handleDimensionsNameOverrideDimensionChange}>
              <option value="">---</option>
              {renderDimensionOverrideOptions()}
          </select>
        </div>

        <div>
          <div className="mb-2">
            <input type="name" className="form-control" data-testid="dimensionNameOverrideName" placeholder='Override Name' onChange={handleDimensionsNameOverrideNameChange} value={dimensionNameOverrideName} />
          </div>

          <div className="float-right">
            <button type="button" className="btn btn-sm btn-primary" onClick={addDimensionNameOverride} disabled={!dimensionNameOverrideDimension?.length || !dimensionNameOverrideName?.length} >Add</button>
          </div>
        </div>
      </div>
      
      <div>
        {!Object.keys(dimensionNameOverrides).length ? (
          <div className="mt-1">
            <em>There are no dimension name overrides configured</em>
          </div>
        ) : (renderDimensionNameOverridesTable())}
      </div>
    </div>
  ) 
}

export default DimensionNameOverrides;
