import React from 'react';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import SortableMeasure from './SortableMeasure';

const MeasureSelections = (props: any) => {
  const { measureSelections, measures, setMeasures, measuresMap } = props;

  const handleMeasureDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldMeasures = [...measures];
      const oldIndex = oldMeasures.findIndex((measure) => measure.id === active.id);
      const newIndex = oldMeasures.findIndex((measure) => measure.id === over.id);
      const newMeasures = arrayMove(oldMeasures, oldIndex, newIndex);
      setMeasures(newMeasures);
    }
  }

  const handleMeasuresChange = (event: React.FormEvent<HTMLSelectElement>) => {
    let checkedMeasures: any[] = [];

    if (event.target.checked) {
      checkedMeasures = [...measures, {id: event.currentTarget.value, name: measuresMap[event.currentTarget.value]}];
    } else {
      checkedMeasures = measures.filter((measure: any) => measure.id !== event.currentTarget.value);
    }

    setMeasures(checkedMeasures);
  }

  return (
    <div>
      <div>
        <label>Measures</label>
        <div className="overflow-auto" style={{ height: '10rem', border: '1px solid #dce4ec', borderRadius: '0.25rem', padding: '5px' }}>
          {measureSelections?.map((measure: any, i: number) => {     
            return (
              <React.Fragment key={i}>
                <div className="form-check" >
                  <input className="form-check-input" type="checkbox" id={`measureCheckbox_${i}`} data-testid={`measureCheckbox_${i}`} value={measure.id} onChange={handleMeasuresChange} checked={!!measures.find((m: any) => m.id == measure.id)} />
                  <label className="form-check-label" htmlFor={`measureCheckbox_${i}`}>{measure.name}</label>
                </div>
              </React.Fragment>
            ) 
          })}
        </div>
      </div>
      
      <div>
        <div className="mt-3">
          <label>Measures Order</label>
          {measures.length ? (
            <DndContext onDragEnd={handleMeasureDragEnd}>
              <SortableContext items={measures}>
                {measures?.map((measure: any, i: number) => {     
                  return <SortableMeasure key={i} measure={measure}></SortableMeasure>;
                })}
              </SortableContext>
            </DndContext>
          ) : (
            <div>
              <em>No measures selected</em>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MeasureSelections;
